<template>
    <div>
        <modal v-show="isModalVisible" @close="closeModal">
            <span slot="body"> {{ errorMessage }}</span>
        </modal>
    </div>
</template>
<script>
import modal from "../../../components/modal";
export default {
    props: ["isModalVisible", "errorMessage", "loading"],
    components: {
        modal,
    },
    methods: {
        closeModal() {
            this.$emit("update:isModalVisible", false);
        },
    },
};
</script>
